export default function ({ $axios }, inject) {
  $axios.onRequest((config) => {
    // config.auth = {
    //   username: this.$config.KIRBY_USERNAME,
    //   password: this.$config.KIRBY_PASSWORD,
    // };
  });
  /*
	$axios.interceptors.response.use(
       response => {
         if(response.status === 200) {
			 console.log('success')
         } else {
			 console.log('error')
		 }
       }
   )*/
}
